import React from "react";



function fnBrowserDetect() {
                 
    let userAgent = navigator.userAgent;
    let browserName;
    
    if(userAgent.match(/chrome|chromium|crios/i)){
        browserName = "chrome";
      }else if(userAgent.match(/firefox|fxios/i)){
        browserName = "firefox";
      }  else if(userAgent.match(/safari/i)){
        browserName = "safari";
      }else if(userAgent.match(/opr\//i)){
        browserName = "opera";
      } else if(userAgent.match(/edg/i)){
        browserName = "edge";
      }else{
        browserName="No browser detection";
      }
  
      return(browserName)
    } 

    
  export function fetchJSON(url: string, callback: Function)  {
    //let url="https://www.oregonembedded.com/genericio.php?operation=track&asset=6365553226"
    //url='http://myapp.oregonembedded.com/my.php' 
    
    fetch(url,{method: "GET", cache: "reload"})
      .then(function (response) {
        return response.text(); })
      .then(function (json) {
        // This is the HTML from our response as a text string
        //debugger;
        console.log(json);
        if (callback!=null) {
            try {
              let obj=JSON.parse(json);
              obj.success=true;
              json=JSON.stringify(obj);
              callback(json);
            } catch {
              callback(JSON.stringify({success:false,message:"Something went wrong with fetch"}));
            }
        } })
      .catch(function (err) {
        // There was an error
        console.warn('Something went wrong.', err);
        if (callback!=null) callback(JSON.stringify({success:false,Accepted:false,message:"Something went wrong with fetch"})); 
      });
}



export function myFetch(callback: Function)  {
    let url="https://www.oregonembedded.com/genericio.php?operation=track&asset=6365553226"
    //url='http://myapp.oregonembedded.com/my.php' 
    
    fetch(url).then(function (response) {
        // The API call was successful!
        //debugger;
        return response.text();
        //console.log("This was sdfg gotten from url: "+url+" "+response.text());
        //if (callback!=null) callback(response.text);
    }).then(function (html) {
        // This is the HTML from our response as a text string
        debugger;
        console.log("This was gotten from url: "+url+" "+html);
        if (callback!=null) callback(url+"   "+html);
    }).catch(function (err) {
        // There was an error
        console.warn('Something went wrong.', err);
        if (callback!=null) callback("Something went wrong");
    });
}


export function myTest(props:any) {
    return(
        <div>
            Craig was here in typescript in office!
        </div>
    )
}