//import logo from './logo.svg';
import './App.css';
import React from "react";
import { useRef, useEffect, useState } from 'react';
import { Color, OpenStreetMapImageryProvider, Cartesian3, ScreenSpaceEventType, Viewer as CesiumViewer } from "cesium";
import { Viewer, Entity, PointGraphics, Camera, CameraFlyTo, ScreenSpaceEvent, ScreenSpaceEventHandler, CesiumComponentRef } from "resium";
import { Stack, DefaultButton, Slider, TextField, Text } from '@fluentui/react';
import * as Test from './test.tsx';

let osm = new OpenStreetMapImageryProvider({
  url : 'https://a.tile.openstreetmap.org/'
});





function fnBrowserDetect() {
                 
  let userAgent = navigator.userAgent;
  let browserName;
  
  if(userAgent.match(/chrome|chromium|crios/i)){
      browserName = "chrome";
    }else if(userAgent.match(/firefox|fxios/i)){
      browserName = "firefox";
    }  else if(userAgent.match(/safari/i)){
      browserName = "safari";
    }else if(userAgent.match(/opr\//i)){
      browserName = "opera";
    } else if(userAgent.match(/edg/i)){
      browserName = "edge";
    }else{
      browserName="No browser detection";
    }

    return(browserName)
  } 

//document.querySelector("h1").innerText="You are using "+ browserName +" browser";         
function deepCopy(obj) {
  if (obj==null) return(null);

  let x=JSON.stringify(obj);
  return (JSON.parse(x));
}


//create your forceUpdate hook
function useForceUpdate(){
  const [value, setValue] = useState(0); // integer state
  return () => setValue(value => value + 1); // update state to force render
  // A function that increment 👆🏻 the previous state like here 
  // is better than directly setting `setValue(value + 1)`
}


let pointColorState=0;
let paints = 0;
let suppressFlyTo = false;
let colorChangeTimeout = false;
let fetchTimeout = false;
let rerenders = 0;




export default function App(props) {
  const [PHPTEXT, setPhpText] = useState("");
  const [PHPOBJ, setPhpObject] = useState(null);
  const [LATITUDE, setLatitude] = useState(500);
  const [LONGITUDE, setLongitude] = useState(500);
  const [REPAINT, setRepaint] = useState(false);
  const [ASSETNUMBER, setAssetNumber] = useState("5415171210");
  const [ZOOM, setZoom] = useState(50000);
  const [DEBUG, setDebug] = useState(false);
  const [RERENDER, setRerender] = useState(0);
  const [POINTCOLOR, setPointColor] = useState(deepCopy(Color.RED));
  const myView = useRef(0); //<CesiumComponentRef<CesiumViewer>>
  const tickIntervalTimer = useRef(null);

  let viewportWidth=window.innerWidth;
  let viewportHeight=window.innerHeight;


  // call your hook here
  const forceUpdate = useForceUpdate();

  const initiateFetch = () => {
    Test.fetchJSON("https://www.oregonembedded.com/genericio.php?operation=track&asset="+ASSETNUMBER,cback) 
    console.log("Fetch");
    //setTimeout(()=>{ changeColor() },4000);
  }

  const changeColor = () => {
    let color=deepCopy(POINTCOLOR);
    if (color.alpha!=1) color.alpha=1;
    else color.alpha=.5;
    
    setPointColor(color);
    //setRerender(RERENDER+1);
    console.log(RERENDER+" ChangeColor " + JSON.stringify(color));
 
    //setTimeout(()=>{changeColor()},1000);
  }

  const ticker = () => {
    rerenders++;
    setRerender(rerenders);

    if (pointColorState==true) pointColorState=false;
    else pointColorState=true;
  }


  useEffect(() => {
    //Test.fetchJSON("https://www.oregonembedded.com/genericio.php?operation=track&asset=6365553226",cback); 
    suppressFlyTo=false;
    paints=0;
    if (fetchTimeout==false) { fetchTimeout=setTimeout(()=>{ initiateFetch() },1000); fetchTimeout=true; }
    //if (colorChangeTimeout==false) { setTimeout(() => {setInterval(()=>{ changeColor() },1000)},2000 ); colorChangeTimeout=true; }
    if (tickIntervalTimer.current==null) { tickIntervalTimer.current = setInterval(ticker,1000); }

    console.log("Use effect!");

    return () => {
      if (tickIntervalTimer.current!=null) { clearInterval(tickIntervalTimer.current); tickIntervalTimer.current=null;}
    }

  },[]);  //By returning an empty array, we are effectively saying "no conditions will cause a re-run of this effect"  


  const getViewer = (verbose=false) => {
    if (myView==null){
      if (verbose) console.error("myView was null!");
      return null;
    } 
    if (myView==undefined){
      if (verbose) console.error("myView was undefined!");
      return null;
    }
    if (myView.current==null){
      if (verbose) console.error("myView.current was null!");
      return null;
    } 
    let viewer = myView.current.cesiumElement;
    if (viewer==null){
      if (verbose) console.error("viewer was null!");
      return null;
    }
    if (verbose) console.log("We have the viewer!");
    return viewer;
  }


  const cback = (json) => {
    
    try {
      let obj = JSON.parse(json);
      if ((obj.Accepted)&&(obj.success)) {
        setPhpObject(obj);
        setPhpText(json);
        setTimeout(()=>{recenter(json)},1000);
      } else {
        setPhpText("");
        setPhpObject(null);
      }
    } catch {console.log("Unable to parse callback text.")}
    

  }

  const recenter = (json=null) => {
    try {
      let obj=null;
      if (json!=null) obj=JSON.parse(json);
      else obj=JSON.parse(PHPTEXT);
      setLatitude(obj.Latitude*1);
      setLongitude(obj.Longitude*1);
      setRepaint(true)
    } catch {
      setLatitude(500);
      setLongitude(500);
    } 

  }


  /*
  <header className="App-header">      
  </header>

  */


 const updateCameraPosition = () => {
  setTimeout(()=>{setRepaint(false)},100); 
  return (<CameraFlyTo duration={2} destination={Cartesian3.fromDegrees(LONGITUDE, LATITUDE, ZOOM)} />);
 }

 const handleChange = (e, value) => {
  setAssetNumber(value);
 }

 const placeGraphic = (lat, lon, alt, color) => {
  let narrative="";
  narrative=narrative+"LAT: "+lat;
  narrative=narrative+"<br/>";
  narrative=narrative+"LON: "+lon;
  narrative=narrative+"<br/><br/>";

//ellipsoid.toCartographic3(scene.getCamera().position).height

return (
    <Entity onMouseEnter={(a,b)=> {}} selected={false} selectable={false} name={ASSETNUMBER}  tracked={false} description={narrative} position={Cartesian3.fromDegrees(lon, lat, alt)  }>
      <PointGraphics color={color} outlineColor={Color.BLACK} pixelSize={8}/>
    </Entity>
  )
}

 const placePointGraphics = (lat,lon) => {
  let alt=1;

  let color;

  if (pointColorState==true) color=Color.RED;
  if (pointColorState==false) color=Color.DARKRED;


  let idx=0;
  let elements = [];

  elements[idx]=placeGraphic(lat,lon,alt,color);
  idx++;

  return(elements);
}




const handleWheel = (movement) => {
  try {
    let viewer=getViewer(true);
    let altitude=viewer.camera.positionCartographic.height;
  //debugger;
    setZoom(altitude);
    console.log(altitude);
  } catch {}

/*
  let res=ZOOM+movement;
  if (res<1) res=1;
  if (res>200000) res=200000;
  setZoom(res);
  */
  //ellipsoid.toCartographic3(scene.getCamera().position).height
  //console.log(altitude);
}


//const sliderValueFormat = (value) => `${value}%`;
/*

  <Test.myTest/> 
 {fnBrowserDetect()}
*/
//disabled={(PHPTEXT=!"")} 
  return (
    <Stack verticalFill verticalAlign='baseline' horizontalAlign="center">
      {console.log("Render")}
      <div style={{minWidth: "300px", minHeight: "800px"}}>
      <Stack horizontalAlign="baseline" display="inlineBlock">

          <div style={{width: "90vw"}}>

            <Stack vertical>
              <Stack>
                <h1>Asset Tracker</h1>
              </Stack>
              
              <Stack horizontal>
                <Text>&nbsp;<br/></Text><br/><br/>
                <Text variant="large">Number:&nbsp;</Text>
                <TextField name="ASSETNUMBER" onChange={handleChange} value={ASSETNUMBER}/>&nbsp;&nbsp;
                <DefaultButton text="Go" onClick={() => { initiateFetch() }}/> 
              </Stack>
              
              <Stack><Text>&nbsp;<br/></Text></Stack>
              
              <Stack horizontal horizontalAlign="baseline">
                <DefaultButton disabled={PHPTEXT==""} text="<<" onClick={() => { let res=ZOOM*10; if (res>600000) res=600000; setZoom(res); setRepaint(true);}}/>
                &nbsp;&nbsp; 
                <DefaultButton disabled={PHPTEXT==""} text="<" onClick={() => { let res=ZOOM*2; if (res>600000) res=600000; setZoom(res); setRepaint(true);}}/>
                &nbsp;&nbsp; 
                <DefaultButton disabled={PHPTEXT==""} text=">" onClick={() => { let res=ZOOM*0.5; if (res<100) res=100; setZoom(res); setRepaint(true);}}/>
                &nbsp;&nbsp; 
                <DefaultButton disabled={PHPTEXT==""} text=">>" onClick={() => { let res=ZOOM*0.05; if (res<100) res=100; setZoom(res); setRepaint(true);}}/>
              </Stack>


              {(PHPOBJ!=null) ? 
                <Stack>
                  <Text variant="small"><br/>
                    Asset time: {PHPOBJ.LocalTime}&nbsp;&nbsp;
                    Alt: {PHPOBJ.Altitude.slice(0,6)} ft&nbsp;&nbsp;
                    Speed: {PHPOBJ.Speed.slice(0,6)} mph&nbsp;&nbsp;
                  </Text> 
                </Stack>
                : null }
            </Stack>
          </div>
        <Stack >
            <div style={{width: "90vw", minHeight: "500px"}}>
            <Viewer style={{ height: '500px', top: '0%', left: '0%', width: '90vw', maxWidth: '500px'}} 
                    ref={myView} id="viewer"
                    animation={false} timeline={false} fullscreenButton={false} 
                    geocoder={false} sceneModePicker={false} baseLayerPicker={false} 
                    imageryProvider={osm} homeButton={false} navigationHelpButton={false}>
            
              <Camera defaultZoomAmount={0} />
              {((LATITUDE<200)&&(REPAINT)) ?  updateCameraPosition() : null } 
              {((LATITUDE<200)&&(POINTCOLOR!=Color.BLACK)) ? placePointGraphics(LATITUDE,LONGITUDE) : null }
              
              <ScreenSpaceEventHandler>
                <ScreenSpaceEvent action={(evt) => handleWheel(evt)} type={ScreenSpaceEventType.WHEEL} />
              </ScreenSpaceEventHandler>
            </Viewer>

            </div>
        </Stack>
        {(DEBUG) ? 
        <Stack>
          <div style={{wordWrap: "break-word", maxWidth: '500px'}}>
              <Text variant="small">{PHPTEXT}<br/>Ticks: {RERENDER}</Text> 
            </div>
        </Stack> : null }
        <Stack>
          <DefaultButton text="Toggle debug" onClick={() => { setDebug(!DEBUG) }}/> 
        </Stack>
      </Stack>
      </div>
    </Stack>

  );
}


/*
Hb: {RERENDER}


                <DefaultButton disabled={PHPTEXT==""} text="X" onClick={() => { recenter() }}/>
                &nbsp;&nbsp;


*/



/*

              <ScreenSpaceEventHandler>
                <ScreenSpaceEvent action={(evt) => handleWheel(evt)} type={ScreenSpaceEventType.WHEEL} />
              </ScreenSpaceEventHandler>

*/